import haversine from 'haversine';
import { SchoolDistanceTitleProps } from '@gf/cross-platform-lib/modules';
import { useLocation } from '@gf/cross-platform-lib/providers';
import { numberWithCommas } from '@gf/cross-platform-lib/utils/numberWithCommas';
import isEmpty from 'lodash/isEmpty';

export const useSchoolDistance = ({ schoolLocation }: SchoolDistanceTitleProps) => {
  const { location } = useLocation();
  let distance;

  if (!isEmpty(location)) {
    distance = Math.floor(
      haversine(
        {
          latitude: location?.coords.latitude,
          longitude: location?.coords.longitude
        },
        schoolLocation,
        { unit: 'mile' }
      )
    );
    distance = numberWithCommas(distance);
  }
  return distance;
};
