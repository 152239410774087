import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  searchResultTitleContainer: css`
    border: 1px solid #cccccc;
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 16px 16px 8px;
    z-index: 3;
  `,
  title: css`
    font-family: 'Rubik-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `
};
