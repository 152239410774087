import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  tag: css<{ hasFilteredSchool?: boolean; isMobile: boolean }>`
    ${baseStyles.tag};
    height: fit-content;
  `,
  tagTitle: css`
    ${baseStyles.tagTitle}
  `
};
