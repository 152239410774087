import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './ToggleFavorite.styles';
// @ts-ignore
import { TouchableOpacity, View } from 'react-native';
import { addHexOpacity, testProperties } from '@gf/cross-platform-lib/utils';
import SoftStarFilled from '@gf/cross-platform-lib/components/Icons/SoftStarFilled';
import SoftStarOutline from '@gf/cross-platform-lib/components/Icons/SoftStarOutline';
import { useLaunchDarklyContext } from '@gf/cross-platform-lib/providers/LaunchDarkly';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons/Carbonicons';

export interface FavoriteProps {
  isFavorite: boolean;
  toggleFavorite: (e?: any) => void;
  backgroundHex?: string;
  backgroundOpacity?: number;
  iconFill?: string;
  iconHeight?: string;
  iconWidth?: string;
  padding?: number;
}

const IconContainer = styled(View)`
  ${styles.iconContainer}
`;

const Wrapper = styled(View)`
  ${styles.wrapper}
`;

export const ToggleFavorite = ({
  isFavorite,
  toggleFavorite,
  backgroundHex = '#ECECF0',
  backgroundOpacity = 1,
  iconFill,
  iconHeight = '18px',
  iconWidth = '18px',
  padding = 6
}: FavoriteProps) => {
  const launchDarklyContext = useLaunchDarklyContext();
  const teamsInGoFanFlag = launchDarklyContext.features['teams-in-gofan'].variation as boolean;

  const renderIcon = () => {
    if (teamsInGoFanFlag) {
      return isFavorite ? (
        <SoftStarFilled testID='star-filled' height={iconHeight} width={iconWidth} fill={iconFill} />
      ) : (
        <SoftStarOutline testID='star-outline' height={iconHeight} width={iconWidth} fill={iconFill} />
      );
    } else {
      return isFavorite ? (
        <Carbonicons name='favorite-filled' color='#e31736' size={18} />
      ) : (
        <Carbonicons name='favorite' color='#e31736' size={18} />
      );
    }
  };

  return (
    <Wrapper>
      <TouchableOpacity
        onPress={toggleFavorite}
        accessibilityLabel='Toggle Favorite'
        accessibilityRole='button'
        {...testProperties('toggle-favorite-button')}
      >
        <IconContainer
          {...testProperties(isFavorite ? 'favorited' : 'un-favorite')}
          backgroundColor={addHexOpacity(backgroundHex, backgroundOpacity)}
          padding={padding}
        >
          {renderIcon()}
        </IconContainer>
      </TouchableOpacity>
    </Wrapper>
  );
};
