import React from 'react';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './InvolvedSchoolTag.styles';
import { Text, View } from 'react-native';
import { Carbonicons } from '@gf/cross-platform-lib/components/Icons';

const Tag = styled(View)`
  ${styles.tag};
`;

const TagTitle = styled(Text)`
  ${styles.tagTitle};
`;

export interface InvolvedSchoolTagProps {
  showHomeIcon: boolean;
  title: string;
  isMobile: boolean;
  hasFilteredSchool?: boolean;
  textColor?: string;
}

export const InvolvedSchoolTag = ({
  showHomeIcon,
  title,
  hasFilteredSchool,
  isMobile,
  textColor
}: InvolvedSchoolTagProps) => {
  return (
    <Tag hasFilteredSchool={hasFilteredSchool} isMobile={isMobile}>
      {showHomeIcon && <Carbonicons name='involvedSchool' size={isMobile ? 12 : 16} />}
      <TagTitle numberOfLines={1} textColor={textColor} hasIcon={showHomeIcon}>
        {title}
      </TagTitle>
    </Tag>
  );
};
