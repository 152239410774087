import React from 'react';
import { AnimatedList } from '@gf/cross-platform-lib/components';
import { Text, View } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import { SearchResultItem } from '../SearchResultItem';
import { styles } from './SearchResult.styles';
import { School } from '@gf/cross-platform-lib/interfaces';
import { homePage, testProperties } from '@gf/cross-platform-lib/utils';

const Title = styled(Text)`
  ${styles.title}
`;

const SearchResultTitleContainer = styled(View)`
  ${styles.searchResultTitleContainer}
`;

export type SearchResultProps = {
  huddleIDs: string[];
  favoriteSchoolIDs?: string[];
  onItemClick?: (id: string) => void;
  onToggleFavorite: (id: string, schoolName: string) => void;
  title?: string;
  filterSchools?: School[];
  applyFilter?: (type: string, selected: boolean, params: any) => void;
  displaySchoolSearch?: boolean;
  testId?: string;
};

export const SearchResult = ({
  huddleIDs,
  onItemClick,
  onToggleFavorite,
  favoriteSchoolIDs,
  title,
  filterSchools,
  applyFilter,
  displaySchoolSearch,
  testId = ''
}: SearchResultProps) => {
  const renderItem = ({ item, index }: { item: string; index: number }) => {
    const huddleID = item;
    return (
      <SearchResultItem
        huddleID={huddleID}
        itemId={huddleID || Date.now().toString()}
        onItemClick={onItemClick}
        key={huddleID}
        isFavorite={favoriteSchoolIDs?.includes(huddleID) ?? false}
        onToggleFavorite={onToggleFavorite}
        lastItem={index + 1 === huddleIDs.length}
        searchCategory={title}
        filterSchools={filterSchools}
        applyFilter={applyFilter}
        displaySchoolSearch={displaySchoolSearch}
      />
    );
  };
  return (
    <View
      {...testProperties(testId || '')}
      style={{
        zIndex: 4,
        backgroundColor: 'white'
      }}
    >
      <SearchResultTitleContainer>
        <Title {...testProperties(homePage.searchResults.title(title || ''))}>{title}</Title>
      </SearchResultTitleContainer>
      <AnimatedList
        data={huddleIDs}
        renderItem={renderItem}
        keyExtractor={item => item}
        usingFlatList={false}
        deactivedOn={['android']}
      />
    </View>
  );
};
