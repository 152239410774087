import { css } from '@gf/cross-platform-lib/styled-components';
/*
*      position: ${({ displaySchoolSearch = false }) =>
      displaySchoolSearch ? 'absolute' : 'relative'};
* */
export const styles = {
  noResultWrapper: css`
    background-color: white;
    padding: 12px;
    border: 2px solid #a8a8a8;
    margin-top: -2px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 4;
  `,
  searchResultWrapper: css<{
    displaySchoolSearch: boolean;
    maxHeightResultList: number;
    showingSearchResult: boolean;
    isMobile: boolean;
  }>`
    background-color: ${({ displaySchoolSearch = false }) => (displaySchoolSearch ? 'transparent' : 'white')};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: ${({ displaySchoolSearch = false, isMobile = false }) =>
      isMobile && displaySchoolSearch ? 'absolute' : 'relative'};
    top: ${({ isMobile = false, displaySchoolSearch = false }) => (isMobile && displaySchoolSearch ? '48px' : 'auto')};
    height: ${({ maxHeightResultList = 0, showingSearchResult = false, displaySchoolSearch = false }) =>
      displaySchoolSearch && showingSearchResult ? `${maxHeightResultList}px` : 'auto'};
    max-height: ${({ maxHeightResultList = 0, displaySchoolSearch }) =>
      displaySchoolSearch ? `350px` : `${maxHeightResultList}px`};
    width: ${({ isMobile = false }) => (isMobile ? '100%' : 'auto')};
    max-width: ${({ displaySchoolSearch = false }) => (displaySchoolSearch ? '320px' : '960px')};
  `,
  searchResultList: css<{ showBottomBorder?: boolean; isMobile: boolean; maxHeight: number }>`
    max-height: ${({ maxHeight }) => `${maxHeight}px`};
    overflow-y: auto;
    border-bottom-width: ${({ showBottomBorder }) => (showBottomBorder ? '1px' : '0')};
    border-bottom-color: #cccccc;
    border-style: solid;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  `,
  title: css`
    font-family: 'Rubik-SemiBold';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  subtitle: css`
    font-size: 14px;
    font-weight: 400;
    color: #a8a8a8;
    margin-top: 4px;
  `
};
