import React from 'react';
import { Platform, Text, View } from 'react-native';
import {
  SchoolSearchAnimationProvider,
  SearchPageContextProps,
  useSearchPageContext
} from '@gf/cross-platform-lib/providers';

import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SchoolSearch.styles';
import { SearchBox, SearchBoxProps } from './SearchBox';
import { SearchResult, SearchResultProps } from './SearchResult';
import { SEARCH_RESULT_CATEGORIES } from '@gf/cross-platform-lib/constants';
import { ScrollView } from 'react-native-gesture-handler';
import isEmpty from 'lodash/isEmpty';
import { homePage, testProperties } from '@gf/cross-platform-lib/utils';

const NoResultWrapper = styled(View)`
  ${styles.noResultWrapper}
`;

const SearchResultWrapper = styled(View)`
  ${styles.searchResultWrapper}
`;
const SearchResultList = styled(View)`
  ${styles.searchResultList}
`;

const Title = styled(Text)`
  ${styles.title}
`;

const Subtitle = styled(Text)`
  ${styles.subtitle}
`;

export type SchoolSearchProps = {
  favIDsToSchoolName: { [key: string]: string };
  recentIDsToVisitDate: { [key: string]: Date };
  toggleSearchFocused?: SearchPageContextProps['toggleSearchFocused'];
} & SearchBoxProps &
  SearchResultProps;

export const SchoolSearch = ({
  favIDsToSchoolName,
  huddleIDs,
  onChange,
  onClearInput,
  onItemClick,
  onToggleFavorite,
  placeholder,
  recentIDsToVisitDate,
  query,
  filterSchools,
  applyFilter,
  displaySchoolSearch,
  hasFilteredSchool
}: SchoolSearchProps) => {
  const { toggleSearchFocused, active, setActive, focusSearchInput, searchResultHeight } = useSearchPageContext();
  const showingSearchResult = active && !!huddleIDs && !hasFilteredSchool;
  const favoriteSchoolIDs = Object.keys(favIDsToSchoolName);
  const filteredFavoriteSchoolIDs = huddleIDs
    .filter(huddleID => (favIDsToSchoolName[huddleID] ? true : false))
    .slice(0, 15)
    .sort((a, b) => favIDsToSchoolName[a].localeCompare(favIDsToSchoolName[b]))
    .map(huddleID => huddleID);
  const filteredRecentSchoolIDs = huddleIDs
    .filter(huddleID => (recentIDsToVisitDate[huddleID] ? true : false))
    .filter(huddleID => !filteredFavoriteSchoolIDs.includes(huddleID))
    .sort((a, b) => (recentIDsToVisitDate[a] > recentIDsToVisitDate[b] ? -1 : 1))
    .map(huddleID => huddleID)
    .slice(0, 5);
  const filteredHuddleIds = huddleIDs.filter(
    huddleID => !filteredFavoriteSchoolIDs.includes(huddleID) && !filteredRecentSchoolIDs.includes(huddleID)
  );

  const onInputActived = () => {
    if (!active) {
      setActive(true);
      toggleSearchFocused && toggleSearchFocused(true);
    }
  };

  const onSearchInputChange = (e: any) => {
    onInputActived();
    onChange(e);
  };

  const titleSectionHeight =
    (filteredFavoriteSchoolIDs.length > 0 ? 60 : 0) +
    (filteredRecentSchoolIDs.length > 0 ? 60 : 0) +
    (filteredHuddleIds.length > 0 && !isEmpty(query) ? 60 : 0);

  const maxHeightResultList =
    titleSectionHeight +
      (filteredFavoriteSchoolIDs.length + filteredRecentSchoolIDs.length + (query ? filteredHuddleIds.length : 0)) *
        80 || 200;

  return (
    <SchoolSearchAnimationProvider maxWidth={hasFilteredSchool ? 320 : 960}>
      <SearchBox
        onChange={onSearchInputChange}
        onPressIn={onInputActived}
        placeholder={placeholder || 'Search for school, mascot, or city'}
        query={query}
        hasClearIcon={!!query}
        onClearInput={onClearInput}
        showingSearchResult={showingSearchResult}
        hasFilteredSchool={hasFilteredSchool}
        displaySchoolSearch={displaySchoolSearch}
      />
      <SearchResultWrapper
        showingSearchResult={showingSearchResult}
        isMobile={Platform.OS !== 'web'}
        maxHeightResultList={displaySchoolSearch ? maxHeightResultList : searchResultHeight}
        displaySchoolSearch={displaySchoolSearch}
        onMouseLeave={() => {
          focusSearchInput();
        }}
      >
        <SearchResultList
          {...testProperties(homePage.searchComponent.searchComponent)}
          showBottomBorder={showingSearchResult}
          maxHeight={searchResultHeight}
        >
          <ScrollView
            keyboardShouldPersistTaps='handled'
            nestedScrollEnabled={true}
            horizontal={false}
            scrollEventThrottle={16}
          >
            {showingSearchResult && filteredFavoriteSchoolIDs.length > 0 && (
              <SearchResult
                huddleIDs={filteredFavoriteSchoolIDs}
                onItemClick={onItemClick}
                onToggleFavorite={onToggleFavorite}
                favoriteSchoolIDs={favoriteSchoolIDs}
                title={SEARCH_RESULT_CATEGORIES.MY_FAVORITES}
                filterSchools={filterSchools}
                applyFilter={applyFilter}
                displaySchoolSearch={displaySchoolSearch}
                testId={homePage.searchList.myFavorite}
              />
            )}
            {showingSearchResult && filteredRecentSchoolIDs.length > 0 && (
              <SearchResult
                huddleIDs={filteredRecentSchoolIDs}
                onItemClick={onItemClick}
                onToggleFavorite={onToggleFavorite}
                favoriteSchoolIDs={favoriteSchoolIDs}
                title={SEARCH_RESULT_CATEGORIES.RECENTLY_VIEWED}
                filterSchools={filterSchools}
                applyFilter={applyFilter}
                displaySchoolSearch={displaySchoolSearch}
                testId={homePage.searchList.recentlyView}
              />
            )}
            {showingSearchResult && filteredHuddleIds.length > 0 && query && (
              <SearchResult
                huddleIDs={filteredHuddleIds}
                onItemClick={onItemClick}
                onToggleFavorite={onToggleFavorite}
                favoriteSchoolIDs={favoriteSchoolIDs}
                title={SEARCH_RESULT_CATEGORIES.SEARCH_RESULTS}
                filterSchools={filterSchools}
                applyFilter={applyFilter}
                displaySchoolSearch={displaySchoolSearch}
                testId={homePage.searchList.result}
              />
            )}
            {filteredHuddleIds.length + filteredRecentSchoolIDs.length + filteredFavoriteSchoolIDs.length === 0 &&
              !!query && (
                <NoResultWrapper {...testProperties(homePage.searchList.noResult)}>
                  <Title>No results found.</Title>
                  <Subtitle>Try again using a different term.</Subtitle>
                </NoResultWrapper>
              )}
          </ScrollView>
        </SearchResultList>
      </SearchResultWrapper>
    </SchoolSearchAnimationProvider>
  );
};
