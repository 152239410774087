import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css<{ showingSearchResult?: boolean; displaySchoolSearch: boolean }>`
    display: flex;
    flex-direction: row;
    height: 48px;
    width: 100%;
    background-color: white;
    border: 2px solid #a8a8a8;
    align-items: center;
    border-radius: 4px;
    border-bottom-left-radius: ${({ showingSearchResult }) => (showingSearchResult ? '0' : '4px')};
    border-bottom-right-radius: ${({ showingSearchResult }) => (showingSearchResult ? '0' : '4px')};
    max-width: ${({ displaySchoolSearch = false }) => (displaySchoolSearch ? '320px' : '960px')};
  `,
  searchIcon: css`
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
    border: 0;
    align-items: center;
    display: flex;
    color: #525252;
  `,
  searchInputWrapper: css`
    flex: 1;
    width: auto;
  `,
  searchInput: css`
    width: 100%;
    font-size: 14px;
    font-family: Rubik-Regular;
    color: black;
    border: none;
    font-weight: 300;

    ::placeholder {
      color: #a8a8a8;
    }
  `,
  clearInput: css`
    padding-left: 16px;
    padding-right: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
};
