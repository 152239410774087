import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ showingSearchResult?: boolean; displaySchoolSearch: boolean }>`
    ${baseStyles.wrapper}
  `,
  searchIcon: css`
    ${baseStyles.searchIcon}
  `,
  searchInputWrapper: css`
    ${baseStyles.searchInputWrapper}
  `,
  searchInput: css`
    ${baseStyles.searchInput}
    outline-width: 0 !important;
    outline-style: none;
  `,
  clearInput: css`
    ${baseStyles.clearInput}
    svg {
      &:hover {
        cursor: pointer;
      }
    }
  `
};
