import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  wrapper: css<{ lastItem: boolean }>`
    ${baseStyles.wrapper}

    &:hover {
      cursor: pointer;
    }
  `,
  title: css`
    ${baseStyles.title}
  `,
  subtitle: css`
    ${baseStyles.subtitle}
  `,
  logoSection: css`
    ${baseStyles.logoSection}
  `,
  descriptionSection: css`
    ${baseStyles.descriptionSection}
  `,
  favorItem: css`
    ${baseStyles.favorItem}
  `,
  iconContainer: css`
    ${baseStyles.iconContainer}
  `
};
