import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function SoftStarOutline(props: any) {
  return (
    <Svg width={14} height={14} viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <Path
        d='M6.194 1.102a.9.9 0 011.614 0l1.521 3.082 3.401.494a.9.9 0 01.5 1.535l-2.462 2.4.581 3.387a.9.9 0 01-1.306.948L7.001 11.35l-3.042 1.6a.9.9 0 01-1.306-.95l.58-3.387-2.46-2.399a.9.9 0 01.499-1.535l3.4-.494 1.522-3.082zM7 1.726L5.546 4.674a.9.9 0 01-.677.492l-3.253.473L3.97 7.933a.9.9 0 01.258.796l-.555 3.24 2.91-1.53a.9.9 0 01.837 0l2.91 1.53-.557-3.24a.9.9 0 01.26-.796l2.353-2.294-3.253-.473a.9.9 0 01-.677-.492L7 1.726z'
        fill={props.fill ?? 'black'}
      />
    </Svg>
  );
}

export default SoftStarOutline;
