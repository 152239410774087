import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  wrapper: css<{ lastItem: boolean }>`
    display: flex;
    height: auto;
    min-height: 64px;
    background-color: white;
    align-items: center;
    justify-content: center;
    border: 1px solid #cccccc;
    border-top-width: 0;
    flex-direction: row;
    padding: 6px 0;

    &:last-child {
      border-radius: 0 0 4px 4px;
    }

    ${({ lastItem }) =>
      lastItem &&
      css`
        border-bottom-width: 0;
      `}
  `,
  title: css`
    font-family: Rubik-Regular;
    font-size: 14px;
    font-weight: normal;
    color: #323232;
  `,
  subtitle: css`
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    color: #5b5b5b;
    display: flex;
    flex-direction: row;
  `,
  logoSection: css`
    background-color: white;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
  `,
  descriptionSection: css`
    flex: 1 1 0%;
  `,
  favorItem: css`
    padding: 8px 16px;
  `,
  iconContainer: css`
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  `
};
