import React from 'react';
import { Text, View } from 'react-native';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SchoolDistanceTitle.styles';
import { useSchoolDistance } from '../../../hooks';
import { homePage, testProperties } from '@gf/cross-platform-lib/utils';

const Title = styled(Text)`
  ${styles.title};
`;

const Container = styled(View)``;

interface LocationProps {
  latitude: number;
  longitude: number;
}

export interface SchoolDistanceTitleProps {
  schoolLocation: LocationProps;
}

export const SchoolDistanceTitle = ({ schoolLocation }: SchoolDistanceTitleProps) => {
  const distance = useSchoolDistance({ schoolLocation });
  return (
    <>
      {distance ? (
        <Container style={{ margin: 0, padding: 0 }}>
          <Title {...testProperties(homePage.searchResults.schoolDistance)}>&nbsp;{`(${distance} miles away)`}</Title>
        </Container>
      ) : null}
    </>
  );
};
