import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  tag: css<{ hasFilteredSchool?: boolean; isMobile: boolean }>`
    color: ${({ hasFilteredSchool }) => (hasFilteredSchool ? 'white' : '#323232')};
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: ${({ isMobile }) => (isMobile ? '18px' : '16px')};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 18px;
    background-color: ${({ hasFilteredSchool }) => (hasFilteredSchool ? 'black' : '#dbdbdb')};
    margin: 0 16px 8px 0;
    padding: ${({ isMobile }) => (isMobile ? '0 8px' : '4px 8px')};
    min-height: 24px;
    width: auto;
    max-width: 340px;
    ${({ hasFilteredSchool }) => (hasFilteredSchool ? 'margin-top: 10px' : '')};
  `,
  tagTitle: css<{ textColor?: string; hasIcon?: boolean }>`
    align-items: center;
    color: ${({ textColor = '#323232' }) => textColor};
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: 16px;
    margin-left: ${({ hasIcon }) => (hasIcon ? '6px' : 0)};
  `
};
