import React, { createRef, useEffect } from 'react';
import { useSearchPageContext } from '.';

interface SchoolSearchAnimationProviderProps {
  children: any;
  maxWidth: number | string;
}

export const SchoolSearchAnimationProvider = ({ children, maxWidth = 960 }: SchoolSearchAnimationProviderProps) => {
  const ref = createRef<HTMLDivElement>();
  const { active, setActive, toggleSearchFocused } = useSearchPageContext();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (event.target instanceof Element) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (active && typeof toggleSearchFocused === 'function') {
            toggleSearchFocused(false);
            setActive(false);
          }
        }
      }
    };

    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [active, toggleSearchFocused, ref]);

  return (
    <div style={{ width: '100%', maxWidth: `${maxWidth}px` }} ref={ref}>
      {children}
    </div>
  );
};
