import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  title: css`
    color: #5b5b5b;
    font-family: Rubik-Regular;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0.32px;
    line-height: 16px;
  `
};
