import * as React from 'react';
import { useAutoAnimate } from '@formkit/auto-animate/react';

import styled from '@gf/cross-platform-lib/styled-components';
import { View } from 'react-native';
import { AutoAnimatedProps, IAnimatedListProps } from './AnimatedList.interfaces';
import isEmpty from 'lodash/isEmpty';
import { useMediaQuery } from '@gf/cross-platform-lib/hooks';
import { styles } from './base.styles';

const defaultOptions: AutoAnimatedProps = { duration: 350 };

export function AnimatedList({
  autoAnimateoptions = defaultOptions,
  data,
  renderItem,
  keyExtractor,
  deactivedOn,
  columns = 1,
  divider
}: IAnimatedListProps) {
  const activated = isEmpty(deactivedOn) || deactivedOn?.every(device => device !== 'web');
  const [parentAnimation] = useAutoAnimate(autoAnimateoptions);

  const { isDesktop, isTablet } = useMediaQuery();
  const calculatedColumns = isDesktop ? Math.min(columns, 3) : isTablet ? Math.min(columns, 2) : 1;

  const rows = data.reduce<string[][]>((acc, item, index) => {
    if (index % calculatedColumns === 0) acc.push([]);
    acc[acc.length - 1].push(item);
    return acc;
  }, []);

  return (
    <List ref={activated ? parentAnimation : null}>
      {rows.map((row, rowIndex) => (
        <View key={`row-${rowIndex}`}>
          <Row columns={calculatedColumns}>
            {row.map((item, index) => {
              return (
                <ItemContainer key={keyExtractor ? keyExtractor(item, index) : null} columns={calculatedColumns}>
                  {renderItem({ item, index }, rowIndex)}
                </ItemContainer>
              );
            })}
          </Row>
          {divider && divider(row[0], rowIndex)}
        </View>
      ))}
    </List>
  );
}

const List = styled(View)`
  ${styles.list};
`;

const Row = styled(View)<{ columns: number }>`
  ${styles.row};
`;

const ItemContainer = styled(View)<{ columns: number }>`
  ${styles.itemContainer};
`;
