import { css } from '@gf/cross-platform-lib/styled-components';
import { baseStyles } from './base.styles';

export const styles = {
  searchResultTitleContainer: css`
    ${baseStyles.searchResultTitleContainer}
  `,
  title: css`
    ${baseStyles.title}
  `
};
