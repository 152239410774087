import React from 'react';
import { TextInput, TouchableOpacity, View } from 'react-native';
import { CloseIcon, SearchSchoolIcon } from '@gf/cross-platform-lib/components';
import styled from '@gf/cross-platform-lib/styled-components';
import { styles } from './SearchBox.styles';
import { useSearchPageContext } from '@gf/cross-platform-lib/providers';
import { useMediaQuery, useReturnKeyType } from '@gf/cross-platform-lib/hooks';
import { InvolvedSchoolTag } from '@gf/cross-platform-lib/modules/school/components/EventList/InvolvedSchoolTag';
import { School } from '@gf/cross-platform-lib/interfaces';
import { TabIndexView } from '@gf/cross-platform-lib/components';
import { homePage, testProperties } from '@gf/cross-platform-lib/utils';

const Wrapper = styled(View)`
  ${styles.wrapper};
`;

const SearchIcon = styled(View)`
  ${styles.searchIcon};
`;

const SearchInputWrapper = styled(View)`
  ${styles.searchInputWrapper};
`;

const SearchInput = styled(TextInput)`
  ${styles.searchInput};
`;

const ClearInput = styled(TouchableOpacity)`
  ${styles.clearInput};
`;

export type SearchBoxProps = {
  onChange: (value: string) => void;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onPressIn?: () => void;
  query: string;
  hasClearIcon?: boolean;
  onClearInput?: () => void;
  placeholder?: string;
  showingSearchResult?: boolean;
  filterSchools?: School[];
  hasFilteredSchool?: boolean;
  displaySchoolSearch?: boolean;
};

export const SearchBox = ({
  onChange,
  onFocus,
  onBlur,
  onPressIn,
  onClearInput,
  placeholder,
  query,
  hasClearIcon,
  showingSearchResult,
  hasFilteredSchool,
  displaySchoolSearch
}: SearchBoxProps) => {
  const { isMobile } = useMediaQuery();
  const { focusSearchInput, searchInputRef } = useSearchPageContext();
  const returnKeyType = useReturnKeyType();
  return (
    <TabIndexView>
      <Wrapper
        showingSearchResult={showingSearchResult}
        displaySchoolSearch={displaySchoolSearch}
        {...testProperties(homePage.searchComponent.searchBoxWrapper)}
      >
        <TouchableOpacity
          onPress={() => {
            focusSearchInput();
          }}
        >
          <SearchIcon>
            <SearchSchoolIcon width={14} height={14} color='#525252' />
          </SearchIcon>
        </TouchableOpacity>
        <SearchInputWrapper {...testProperties(homePage.searchComponent.wrapper)}>
          {hasFilteredSchool && (
            <>
              <InvolvedSchoolTag
                hasFilteredSchool={hasFilteredSchool}
                showHomeIcon={false}
                title={query}
                isMobile={isMobile}
                textColor='#ffffff'
              ></InvolvedSchoolTag>
            </>
          )}
          {!hasFilteredSchool && (
            <SearchInput
              ref={searchInputRef}
              value={query}
              onChangeText={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onPressIn={() => onPressIn && onPressIn()}
              placeholder={placeholder}
              type='text'
              autoCapitalize='none'
              placeholderTextColor='#a8a8a8'
              onClick={onPressIn}
              returnKeyType={returnKeyType}
              accessibilityLabel={placeholder}
              {...testProperties(homePage.searchComponent.searchInput)}
            />
          )}
        </SearchInputWrapper>
        {hasClearIcon ? (
          <ClearInput onPress={onClearInput} {...testProperties(homePage.searchComponent.clearInput)}>
            <CloseIcon width={14} height={14} color='#525252' />
          </ClearInput>
        ) : null}
      </Wrapper>
    </TabIndexView>
  );
};
